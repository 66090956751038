import React from "react";
import {Helmet} from "react-helmet";

// Styles
import {HeaderWrapper} from "./Header.styles";
import {Container} from "theme/Theme";

// Assets
import BpiFrance from "assets/img/BpiFrance";
import {useDispatch} from "react-redux";
import {showRetourModal} from "store/user.slice";
import {useHistory, useLocation} from "react-router";

// Types
type HeaderTypes = {
	title?: string;
	url?: string;
	headerRight?: any;
};

function Header({title, url, headerRight}: HeaderTypes): JSX.Element {
	const dispatch = useDispatch();
	const history = useHistory();
	const params = useLocation();
	const handleModal = () => {
		if (params.pathname === "/") {
			return;
		}
		if (params.pathname === "/cgu") {
			history.push("/");
			return;
		}
		dispatch(showRetourModal());
	};
	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>{title}</title>
				<link rel="canonical" href={url} />
			</Helmet>
			<Container>
				<HeaderWrapper
					style={{backgroundColor: url === "conseils" ? "transparent" : "white"}}
				>
					<div
						onClick={() => handleModal()}
						tabIndex={0}
						role="button"
						onKeyPress={(e) => e.keyCode === 13 && handleModal()}
					>
						<BpiFrance />
					</div>
					{headerRight ? <div className="header-right">{headerRight}</div> : null}
				</HeaderWrapper>
			</Container>
		</>
	);
}

export default React.memo(Header);
