import React, {useState, useEffect} from "react";
import Body from "components/layout/Body/Body";
import Button from "components/units/Button/Button";
import {useHistory} from "react-router";
import {Link} from "react-router-dom";

// Styles
import {HomeWrapper} from "./Home.styles";

// Content
import MainIndicator from "assets/img/Indicator.svg";
import GreenIndicator from "assets/img/Greenindicator.svg";
import GreenIndicatorLeft from "assets/img/GreenIndicator2.svg";
import OrangeIndicator from "assets/img/OrangeIndicator.svg";
import {Container} from "theme/Theme";
import {showServicePremiumModal} from "store/user.slice";
import {useDispatch} from "react-redux";
import Checkbox from "components/units/Checkbox/Checkbox";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function Home(props: any): JSX.Element {
	const [isChecked, setIsChecked] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();
	const handleGo = () => {
		if (isChecked) {
			setTimeout(() => history.push("/selection-entreprise"), 300);
		}
	};

	useEffect(() => {
		const query = new URLSearchParams(props.location.search).get("servicePremium");
		if (query === "true") {
			dispatch(showServicePremiumModal());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Body
			url="/"
			title="Diagnostic financier – Bpifrance"
			headerRight={
				<button type="button" className="top-button">
					Inclus dans votre <span className="highlighted">abonnement</span> Bpifrance en
					ligne
				</button>
			}
		>
			<HomeWrapper>
				<Container>
					<div className="card">
						<div className="content">
							<div className="left">
								<button type="button" className="top-button">
									Inclus dans votre{" "}
									<span className="highlighted">abonnement</span> Bpifrance en
									ligne
								</button>
								<h1>Évaluez et faites progresser votre entreprise</h1>
								<div className="images">
									<img className="img-green-indicator" src={GreenIndicatorLeft} alt="Green-indicator" />
									<img className="img-main-indicator" src={MainIndicator} alt="Main-indicator" />
									<img className="img-orange-indicator" src={OrangeIndicator} alt="Orange-indicator" />
								</div>
								<p>
									Cette application de diagnostic financier est proposée par
									Inbonis Rating, partenaire de Bpifrance, afin de vous permettre
									une meilleure compréhension de vos résultats et indicateurs
									financiers.
								</p>
								<p>
									A l’issue de ce diagnostic, des recommandations, plans d’actions
									et des formations Bpifrance Universités vous sont partagés afin
									de vous aider à identifier des leviers.
								</p>
								<p>
									Les résultats obtenus à la suite de la simulation réalisée ne
									sont donnés qu’à titre informatif et n’engagent pas la société
									Bpifrance. Ils ne valent pas un audit personnalisé par un
									expert.
								</p>
								<Checkbox
									id="accept_legal_conditions"
									name="accept_legal_conditions"
									checked={isChecked}
									label={
										<>
											Je reconnais avoir pris connaissance et accepte les{" "}
											<Link to="/cgu">
												Conditions générales d’utilisation
											</Link>{" "}
											d&apos;Inbonis Rating
										</>
									}
									onChange={() => setIsChecked(!isChecked)}
								/>
								<Button
									type="button"
									className={`bottom primary ${isChecked ? '' : 'disabled'}`}
									text="Je démarre mon diagnostic financier"
									disabled={!isChecked}
									onClick={handleGo}
									textStyles={{fontWeight: 500}}
								/>
							</div>
							<div className="right">
								<img className="img-main-indicator" src={MainIndicator} alt="Main-indicator" />
								<img className="img-green-indicator" src={GreenIndicator} alt="Green-indicator" />
								<img className="img-orange-indicator" src={OrangeIndicator} alt="Orange-indicator" />
							</div>
						</div>
					</div>
				</Container>
			</HomeWrapper>
		</Body>
	);
}

export default Home;
